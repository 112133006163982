import { useContext, useEffect, useState } from "react";

import { } from "./styled";

import DashboardCardTabs from "components/Dashboard/Cards/Tabs";
import DashboardCardTable from "components/Dashboard/Cards/Table";
import { useHistory } from "react-router-dom";
import { CoreContext } from "context/CoreContext";
import { exposeStrapiError, normalizeStrapiList } from "utils";
import { ReadAll, ReadAllForEmployee } from "services/report-incomes";

import { pdf } from "@react-pdf/renderer";
import { IncomeStatement } from "components/GeneratePDF/IncomeStatement";
import moment from "moment";
import { parseDatestringUSBR } from "utils/parsers.js";
import { ReadEmployeeByUserId } from "services/employee.js";

export default function DashboardListsRevenues() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);

  const [activeTab, setActiveTab] = useState(0);
  // const [pdfBlob, setPdfBlob] = useState(null);
  const { searchExpression, currentCompany, user } = useContext(CoreContext);

  const options = [{ id: 1, title: "Ver" }];

  const tabs = [
    { title: "Cadastros" },
    // { title:'Erros', badge:2 }
  ];

  const actions = [
    // { title:'Cancelar', outline:true, action:() => history.goBack() },
    ...(user?.isManager ?  [{ title: 'Novo', action: () => navigate("dashboard/revenues/add") }] : [])
  ];

  const columns = [
    { title: "Data e hora da criação", ref: "createdAt" },
    ...(user?.isManager ?  [{ title: "Usuario que importou", ref: "importingUser" }] : []),
    { title: "Exercício do informe", ref: "exercise" },
  ];

  const columnsImport = [
    { title: 'Data de importação', ref: 'dateImport' },
    { title: 'Usuario que importou', ref: 'importingUser' },
    { title: 'Enviado notificação', ref: 'notificationSent' },
    { title: 'Ano', ref: 'year' },
    { title: 'Data atualização', ref: 'updateDate' },
  ]

  const [rows, setRows] = useState([]);

  const columnsTab1 = [
    { title: "CPF", ref: "cpf" },
    { title: "Nome do funcionário", ref: "name" },
    { title: "Ano / Mês", ref: "date" },
    { title: "Tipo de holerite", ref: "type" },
    { title: "Erro", ref: "error" },
  ];

  const [rowsTab1, setRowsTab1] = useState([
    {
      document: "888.888.888-88",
      name: "Mariana da Fonseca da Silva",
      date: "2023 / Agosto",
      type: "Salário",
      error: "Texto do erro",
    },
    {
      document: "888.888.888-88",
      name: "Mariana da Fonseca da Silva",
      date: "2023 / Agosto",
      type: "Salário",
      error: "Texto do erro",
    },
    {
      document: "888.888.888-88",
      name: "Mariana da Fonseca da Silva",
      date: "2023 / Agosto",
      type: "Salário",
      error: "Texto do erro",
    },
    {
      document: "888.888.888-88",
      name: "Mariana da Fonseca da Silva",
      date: "2023 / Agosto",
      type: "Salário",
      error: "Texto do erro",
    },
    {
      document: "888.888.888-88",
      name: "Mariana da Fonseca da Silva",
      date: "2023 / Agosto",
      type: "Salário",
      error: "Texto do erro",
    },
    {
      document: "888.888.888-88",
      name: "Mariana da Fonseca da Silva",
      date: "2023 / Agosto",
      type: "Salário",
      error: "Texto do erro",
    },
    {
      document: "888.888.888-88",
      name: "Mariana da Fonseca da Silva",
      date: "2023 / Agosto",
      type: "Salário",
      error: "Texto do erro",
    },
    {
      document: "888.888.888-88",
      name: "Mariana da Fonseca da Silva",
      date: "2023 / Agosto",
      type: "Salário",
      error: "Texto do erro",
    },
    {
      document: "888.888.888-88",
      name: "Mariana da Fonseca da Silva",
      date: "2023 / Agosto",
      type: "Salário",
      error: "Texto do erro",
    },
    {
      document: "888.888.888-88",
      name: "Mariana da Fonseca da Silva",
      date: "2023 / Agosto",
      type: "Salário",
      error: "Texto do erro",
    },
    {
      document: "888.888.888-88",
      name: "Mariana da Fonseca da Silva",
      date: "2023 / Agosto",
      type: "Salário",
      error: "Texto do erro",
    },
  ]);

  // const generatePDF = async () => {
  //   const blob = await pdf(<IncomeStatement />).toBlob();
  //   setPdfBlob(blob);
  // };

  const downloadIncomeStatement = (row) => {
    const doc = <IncomeStatement register={row} />;

    pdf(doc)
      .toBlob()
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `informe-de-rendimentos-${row.name}.pdf`;
        link.click();
      })
      .catch((err) => {
        console.error("Erro ao gerar o PDF:", err);
      });
  };

  const [loading, setLoading] = useState(false);

  const parseRegister = item => {
    console.log('Item processado:', item);
    return {
      ...item,
      date: parseDatestringUSBR(item.createdAt),
      action: () => user?.isManager ? navigate(`dashboard/revenues/form/${item.uploadId}`) : navigate(`dashboard/revenues/form/${item.id}`),
      uploadId: item.uploadId,
      id: item.id,
      companyId: currentCompany?.id
    };
  };


  const init = async () => {
    setLoading(true);
   
    try{
      if(user?.isManager){
        const incomeResult = await ReadAll(currentCompany?.id);
        // console.log('incomeResult', incomeResult)
        if (incomeResult && !exposeStrapiError(incomeResult)) {
          const normalIncomeRegister = normalizeStrapiList(incomeResult) || [];
    
          const groupedByUploadId = normalIncomeRegister.reduce((acc, item) => {
            if (!acc[item.uploadId]) {
              acc[item.uploadId] = item;
            }
            return acc;
          }, {});
    
          const consolidatedIncomeRegisters = Object.values(groupedByUploadId).map(parseRegister).map(register => {
            register.createdAt = moment(register.createdAt).format("DD/MM/YYYY HH:mm");
            return register;
          });
    
          setRows(consolidatedIncomeRegisters);
        }
      } else {
        const employee = await ReadEmployeeByUserId(user?.id);

        const employeeId = employee.data[0]?.id

        const incomeResult = await ReadAllForEmployee(employeeId);
        console.log('incomeResult', incomeResult)
        if (incomeResult && !exposeStrapiError(incomeResult)) {
          const normalIncomeRegister = normalizeStrapiList(incomeResult) || [];
    
          const consolidatedIncomeRegisters = Object.values(normalIncomeRegister).map(parseRegister).map(register => {
            register.createdAt = moment(register.createdAt).format("DD/MM/YYYY HH:mm");
            return register;
          });
    
          setRows(consolidatedIncomeRegisters);
        }
      }
    } catch (error) {

    }

    setLoading(false);
  };

  useEffect(() => {
    if (currentCompany?.id) {
      init();
    }
  }, [currentCompany]);

  // useEffect(() => {
  //   generatePDF();
  // }, []);

  return (
    <>
      <DashboardCardTabs
        title={"Informes"}
        actions={actions}
        tabs={tabs}
        active={activeTab}
        setActive={setActiveTab}
        selectable={{ options, placeholder: "Ações" }}
      >
        {activeTab === 0 ? (
          <DashboardCardTable
            downloadIncomeStatement={downloadIncomeStatement}
            rows={rows}
            columns={columns}
            searchExpression={searchExpression}
            loading={loading}
          />
        ) : null}
        {activeTab === 1 ? (
          <DashboardCardTable
            rows={rowsTab1}
            columns={columnsTab1}
            searchExpression={searchExpression}
            loading={loading}
            downloadIncomeStatement={downloadIncomeStatement}
          />
        ) : null}
      </DashboardCardTabs>


      {/* <div>
        {pdfBlob && (
          <iframe
            src={URL.createObjectURL(pdfBlob)}
            width="100%"
            height="600px"
          />
        )}
      </div> */}
    </>
  );
}
