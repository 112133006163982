
import {
    CardHomeChart
} from "./styled";

import { Chart } from "react-google-charts";

import DashboardCardWrapper from "../Wrapper";
import { theme } from "ui/theme-color";
import { yearOptions } from "utils";
import { Load, LoadCenter } from "ui/styled";

export default function DashboardCardPaycheck({ register, loading, filterYear }) {
    const mockValues = register?.holeriteMonthCounts?.map(m => [m[0], m[1]]) || []; 
    const data = [
        ["Mês", "Total"],
        ...mockValues
    ];

    const options = {
        curveType: "function",
        legend: { position: "none" },
        colors: [theme.palette.primary.main],
        backgroundColor: theme.palette.colors.backgroundColor,
        tooltip: { trigger: 'selection' },
        vAxis: {
            format: 'currency',
            currency: 'BRL'
        },
        hAxis: {
            title: 'Mês',
        }
    };

    return mockValues.length > 0 ? (
        <DashboardCardWrapper title={"Soma total de todos os holerites"} selectable={{ options: yearOptions, placeholder: "Ano", onChange: filterYear }}>
            <CardHomeChart>
                {
                    loading ?
                        <LoadCenter><Load /></LoadCenter>
                        :
                        <Chart
                            chartType="LineChart"
                            width="100%"
                            height="300px"
                            data={data}
                            options={options}
                        />
                }
            </CardHomeChart>
        </DashboardCardWrapper>
    ) : null;
}
