import axios from 'axios'
import { ReadObject, SaveObject } from './storage'

const ENDPOINTS = {
	// 'localhost': 'http://localhost:1337/api',
	'localhost' : 'https://api.assepontoholerite.com.br/api'
}

const API_FORWARD_ENDPOINT = "https://asseweb.com.br/apileituraholerite/api/v1"

const envEndpoint = () => {
	return (
		Object.keys(ENDPOINTS)
			.filter(fit => `${window.location.origin}`.indexOf(fit) !== -1)
			.map(key => ENDPOINTS[key])[0]
	) || ENDPOINTS['localhost']
}

export const API_ENDPOINT = envEndpoint()

export const GetHeaders = async authenticated => {
	const headers = { 'Content-Type': 'application/json' }
	const authentication = await ReadObject('authentication')
	if (authenticated && authentication.jwt) {
		headers.Authorization = `Bearer ${authentication.jwt}`
	}
	return { headers }
}

export const ServerFetch = async (url, options, authenticated, isBlob = false) => {
	const { headers } = await GetHeaders(authenticated);
	try {
		const response = await fetch(url, { ...options, headers });
		if (!response.ok) {
			throw new Error(`HTTP status ${response.status}`);
		}

		if (isBlob) {
			return response.blob();
		} else {
			return response.json();
		}
	} catch (error) {
		console.log('ServerFetchError', error);
		return false;
	}
}


export const GET = async (path, authenticated = false) => {
	return await ServerFetch(
		`${API_ENDPOINT}${path}`,
		{
			method: 'GET'
		},
		authenticated
	)
}

export const POST = async (path, body, authenticated = false, isBlob = false) => {
	return await ServerFetch(
		`${API_ENDPOINT}${path}`,
		{
			method: 'POST',
			body: JSON.stringify(body)
		},
		authenticated,
		isBlob
	);
}

export const PUT = async (path, body, authenticated = false) => {
	return await ServerFetch(
		`${API_ENDPOINT}${path}`,
		{
			method: 'PUT',
			body: JSON.stringify(body)
		},
		authenticated
	)
}

export const DELETE = async (path, authenticated = false) => {
	return await ServerFetch(
		`${API_ENDPOINT}${path}`,
		{
			method: 'DELETE'
		},
		authenticated
	)
}

export const ReadAddressesByZipCode = async (zipCode) => {
	try {
		let result = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`);
		return result.json();
	} catch (err) { return false; }
}



export const PostImage = async (fileToUpload, forward) => {
	const formData = new FormData()
	let { headers } = await GetHeaders(true)
	if (forward) {

		// if(forward?.type === 'informerendimento'){
		// 	formData.append('Arquivo', fileToUpload, fileToUpload.name)  
		// }
		// if(forward?.type === 'holerite'){
		// 	formData.append('arquivo', fileToUpload, fileToUpload.name)  
		// }

		formData.append('file', fileToUpload, fileToUpload.name)
		formData.append('type', forward?.type)
		formData.append('companyId', forward?.companyId)
		formData.append('payload', JSON.stringify(forward?.payload))

		// Object.keys(forward?.payload).map(k => {
		// 	formData.append(k, forward?.payload[k])
		// })

		// delete headers['Authorization']

		delete headers['Content-Type']

		// let response = await fetch(`${API_FORWARD_ENDPOINT}/Holerite/${ forward?.type }` , { 
		// 	method: 'POST', 
		// 	body: formData, 
		// 	headers 
		// });   


		// let response = await fetch(`${API_ENDPOINT}/holerite-generate-process`, { method: 'POST', body: formData, headers });
		let response = await fetch(`${API_ENDPOINT}/holerite-generate`, { method: 'POST', body: formData, headers });

		try {
			let responseJson = await response.json()
			console.log("Server response", responseJson)
			return responseJson;
		} catch (err) {
			console.log('ServerParseError', err)

			try {
				const errorMessage = `${err.message}`.split(', "')?.[1]?.split('"')?.[0];
				console.log('error', errorMessage);

				if (errorMessage === 'CNPJ error') {
					return { error: true, message: 'CNPJ contido no arquivo não confere com o do cadastro da empresa.' };
				}

				if (errorMessage === 'Competencia error') {
					return { error: true, message: 'Competência do arquivo não corresponde a desta obrigação.' };
				}

				return { error: true, message: errorMessage };
			} catch (error) {
				return false;
			}


		}

	} else {
		formData.append('files', fileToUpload, fileToUpload.name)
		delete headers['Content-Type']

		let response = await fetch(`${API_ENDPOINT}/upload`, { method: 'POST', body: formData, headers });
		let responseJson = await response.json()
		return responseJson;
	}




}

export const PrepareImageFile = (image) => {
	let btc = window.atob(image.source)
	let btn = new Array(btc.length);
	for (var i = 0; i < btc.length; i++) {
		btn[i] = btc.charCodeAt(i);
	}
	var bta = new Uint8Array(btn);
	let boobs = new Blob([bta], { type: 'image/png' });
	return new File([boobs], `${image.filename}`)
}

export const UploadImage = (file, forward) => {
	return new Promise(async (resolve, reject) => {

		console.log("forwarding", forward)

		if (forward) {
			let result = await PostImage(file, forward);
			resolve(result);
			return;
		}

		let reader = new FileReader();
		reader.onload = async () => {
			let source = forward ? reader.result : `${reader.result}`.split(',')[1]
			let image = {
				filename: file.name,
				filetype: file.type,
				source
			}
			let thefile = PrepareImageFile(image);
			let result = await PostImage(thefile, forward);
			resolve(result);
		};
		reader.readAsDataURL(file);
	})
}

export const parseStrapiImage = url => {
	return !url ? '' : url.indexOf('://') !== -1 ? url : `${API_ENDPOINT.replace('/api', '')}${url}`
}