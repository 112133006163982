import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import { styles } from "./styles";

import logo from "../../../assets/brasao.png";
import { convertToNumber, formatToReal } from "utils/validators";

export const IncomeStatement = ({ registers }) => {
  console.log("teste?", registers);
  return (
    <Document>
      {registers.map((register, index) => {

        console.log('register found akhafhskajf', register)

        const informacoesComplementares = register.informacoesComplementares;

        const rendimentosTributaveis = [
          {
            chave: "totalRendimentos",
            titulo: "1. Total dos rendimentos (inclusive férias)",
          },
          {
            chave: "contribuicaoPrevidenciaria",
            titulo: "2. Contribuição previdenciária oficial",
          },
          {
            chave: "contribuicaoPrevidenciariaPrivada",
            titulo:
              "3. Contribuição a entidades de previdência complementar, pública ou privada, e a fundos de aposentadoria programada individual (Fapi)(preencher também o quadro 7)",
          },
          {
            chave: "pensaoAlimenticia",
            titulo: "4. Pensão alimentícia (preencher também o quadro 7)",
          },
          {
            chave: "impostoRendaRetido",
            titulo: "5. Imposto sobre a renda retido na fonte",
          },
        ];

        const rendimentoInsento = [
          {
            chave: "juros",
            titulo: `1. Parcela isenta dos proventos de aposentadoria, reserva
    remunerada, reforma e pensão (65 anos ou mais), exceto a
    parcela isenta do 13o (décimo terceiro) salário`,
          },
          {
            chave: "lucro",
            titulo: `2. Parcela isenta do 13o salário de aposentadoria, reserva
    remunerada, reforma e pensão (65 anos ou mais)`,
          },
          {
            chave: "outros",
            titulo: `3. Diárias e ajuda de custo`,
          },
          {
            chave: "pensao",
            titulo: `4. Pensão e proventos de aposentadoria ou reforma por moléstia
    grave; proventos de aposentadoria ou reforma por acidente em
    serviço`,
          },
          {
            chave: "ajudaCusto",
            titulo: ` 5. Lucros e dividendos, apurados a partir de 1996, pagos por
    pessoa jurídica (lucro real, presumido ou arbitrado)`,
          },
          {
            chave: "pagosTitular",
            titulo: `6. Valores pagos ao titular ou sócio da microempresa ou
    empresa de pequeno porte, exceto pro labore, aluguéis ou
    serviços prestados`,
          },
          {
            chave: "parcelaIsenta",
            titulo: `7. Indenizações por rescisão de contrato de trabalho,
    inclusive a título de PDV e por acidente de trabalho`,
          },
          {
            chave: "parcelaIsenta13",
            titulo: `8. Juros de mora recebidos, devidos pelo atraso no pagamento
    de remuneração por exercício de emprego, cargo ou função`,
          },
          {
            chave: "rescisaoContratoTrabalho",
            titulo: `9. Outros:`,
          },
        ];
        const rendimentoLiquido = [
          {
            chave: "outros",
            titulo: `1. Décimo terceiro salário`,
          },
          {
            chave: "salario13",
            titulo: `2. Imposto sobre a renda retido na fonte sobre 13o salário`,
          },
          {
            chave: "retidoSalario13",
            titulo: `3. Outros`,
          },
        ];

        const rendimentoAcumuladamente = [
          {
            chave: "totalTributavel",
            titulo: `1. Total dos rendimentos tributáveis (inclusive férias e décimo terceiro salário) `,
          },
          {
            chave: "exclusaoAcaoJudicial",
            titulo: `2. Exclusão: Despesas com a ação judicial`,
          },
          {
            chave: "deducaoPrevidenciaria",
            titulo: `3. Dedução: Contribuição previdenciária oficial`,
          },
          {
            chave: "deducaoPensaoAlimenticia",
            titulo: `4. Dedução: Pensão alimentícia (preencher também o quadro 7)`,
          },
          {
            chave: "impostoRetidoFonte",
            titulo: `5. Imposto sobre a renda retido na fonte (IRRF)`,
          },
          {
            chave: "isentosPensao",
            titulo: `6. Rendimentos isentos de pensão, proventos de aposentadoria ou reforma por moléstia grave ou aposentadoria ou reforma
            por acidente em serviço`,
          },
        ];


        return (
          <>
            <Page size="A4" style={styles.page}>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View
                    style={[
                      styles.tableCol,
                      {
                        width: "10%",
                      },
                    ]}
                  >
                    <Image style={styles.image} src={logo} />
                  </View>

                  <View
                    style={[
                      styles.tableCol,
                      {
                        width: "50%",
                        borderRight: 1,
                        justifyContent: "center",
                      },
                    ]}
                  >
                    <Text style={styles.title}>
                      MINISTÉRIO DA ECONOMIA {`\n`}
                      Secretaria Especial da Receita Federal do Brasil {`\n`}
                      Imposto sobre a Renda da Pessoa Física {`\n`}
                      Exercício de {register.anoExercicio}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.tableCol,
                      {
                        width: "50%",
                        justifyContent: "center",
                      },
                    ]}
                  >
                    <Text style={styles.title}>
                      Comprovante de Rendimentos Pagos e de {`\n`}
                      Imposto sobre a Renda Retido na Fonte {`\n`} {`\n`}
                      Ano-calendário de {register.anoCalendario}
                    </Text>
                  </View>
                </View>

                <View
                  style={[
                    styles.tableRow,
                    {
                      marginHorizontal: 9,
                      marginVertical: 4,
                    },
                  ]}
                >
                  <View
                    style={[
                      styles.tableCol,
                      {
                        width: "100%",
                      },
                    ]}
                  >
                    <Text style={styles.text}>
                      Verifique as condições e o prazo para a apresentação da
                      Declaração do Imposto sobre a Renda da Pessoa Física para este
                      ano-calendário no sítio da Secretaria Especial da Receita
                      Federal do Brasil na Internet, no endereço
                      https://www.gov.br/receitafederal/pt-br.
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    marginVertical: 2,
                  }}
                >
                  <Text style={styles.titleTableCol}>
                    1. Fonte Pagadora Pessoa Jurídica
                  </Text>
                  <View style={styles.tableRow}>
                    <View
                      style={[
                        styles.tableCol,
                        {
                          width: "20%",
                          borderRight: 1,
                        },
                      ]}
                    >
                      <Text style={styles.text}>CNPJ</Text>
                      <Text
                        style={[
                          styles.text,
                          {
                            textAlign: "right",
                          },
                        ]}
                      >
                        {register.cnpjCpf}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableCol,
                        {
                          width: "80%",
                        },
                      ]}
                    >
                      <Text style={styles.text}>Nome Empresarial</Text>
                      <Text style={styles.text}>{register.nome}</Text>
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    marginVertical: 2,
                  }}
                >
                  <Text style={styles.titleTableCol}>
                    2. Pessoa Física Beneficiária dos Rendimentos
                  </Text>
                  <View
                    style={[
                      styles.tableRow,
                      {
                        flexDirection: "column",
                      },
                    ]}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <View
                        style={[
                          styles.tableCol,
                          {
                            width: "20%",
                            borderRight: 1,
                          },
                        ]}
                      >
                        <Text style={styles.text}>CPF</Text>
                        <Text
                          style={[
                            styles.text,
                            {
                              textAlign: "right",
                            },
                          ]}
                        >
                          {register.cpf}
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.tableCol,
                          {
                            width: "80%",
                          },
                        ]}
                      >
                        <Text style={styles.text}>Nome Completo</Text>
                        <Text style={styles.text}>{register.nome}</Text>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.tableCol,
                        {
                          width: "100%",
                          borderTop: 1,
                        },
                      ]}
                    >
                      <Text style={styles.text}>Natureza do Rendimento</Text>
                      <Text style={styles.text}>
                        {register.naturezaRendimento}
                      </Text>
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    marginVertical: 2,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={[
                        styles.titleTableCol,
                        {
                          width: "80%",
                          textAlign: "left",
                        },
                      ]}
                    >
                      3. Rendimentos Tributáveis, Deduções e Imposto sobre a Renda
                      Retido da Fonte
                    </Text>
                    <Text
                      style={[
                        styles.titleTableCol,
                        {
                          width: "20%",
                          textAlign: "right",
                        },
                      ]}
                    >
                      Valores em reais
                    </Text>
                  </View>
                  {rendimentosTributaveis.map((item, index) => (
                    <View key={index} style={styles.tableRow}>
                      <View
                        style={[styles.tableCol, { width: "80%", borderRight: 1 }]}
                      >
                        <Text style={styles.text}>{item.titulo}</Text>
                      </View>
                      <View
                        style={[
                          styles.tableCol,
                          { width: "20%", textAlign: "right" },
                        ]}
                      >
                        <Text style={styles.text}>
                          {register.rendimentoTributavel[item.chave]}
                        </Text>
                      </View>
                    </View>
                  ))}
                </View>

                <View
                  style={{
                    marginVertical: 2,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={[
                        styles.titleTableCol,
                        {
                          width: "80%",
                          textAlign: "left",
                        },
                      ]}
                    >
                      4. Rendimentos Isentos e Não Tributáveis
                    </Text>
                    <Text
                      style={[
                        styles.titleTableCol,
                        {
                          width: "20%",
                          textAlign: "right",
                        },
                      ]}
                    >
                      Valores em reais
                    </Text>
                  </View>
                  {rendimentoInsento.map((item, index) => (
                    <View key={index} style={styles.tableRow}>
                      <View
                        style={[styles.tableCol, { width: "80%", borderRight: 1 }]}
                      >
                        <Text style={styles.text}>{item.titulo}</Text>
                      </View>
                      <View
                        style={[
                          styles.tableCol,
                          { width: "20%", textAlign: "right" },
                        ]}
                      >
                        <Text style={styles.text}>
                          {register.rendimentoInsento[item.chave]}
                        </Text>
                      </View>
                    </View>
                  ))}
                </View>

                <View
                  style={{
                    marginVertical: 2,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={[
                        styles.titleTableCol,
                        {
                          width: "80%",
                          textAlign: "left",
                        },
                      ]}
                    >
                      5. Rendimentos Sujeitos à Tributação Exclusiva (rendimento
                      líquido)
                    </Text>
                    <Text
                      style={[
                        styles.titleTableCol,
                        {
                          width: "20%",
                          textAlign: "right",
                        },
                      ]}
                    >
                      Valores em reais
                    </Text>
                  </View>
                  {rendimentoLiquido.map((item, index) => (
                    <View key={index} style={styles.tableRow}>
                      <View
                        style={[styles.tableCol, { width: "80%", borderRight: 1 }]}
                      >
                        <Text style={styles.text}>{item.titulo}</Text>
                      </View>
                      <View
                        style={[
                          styles.tableCol,
                          { width: "20%", textAlign: "right" },
                        ]}
                      >
                        <Text style={styles.text}>
                          {register.rendimentoLiquido[item.chave]}
                        </Text>
                      </View>
                    </View>
                  ))}
                </View>

                <View
                  style={{
                    marginVertical: 2,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={[
                        styles.titleTableCol,
                        {
                          width: "80%",
                          textAlign: "left",
                        },
                      ]}
                    >
                      6. Rendimentos Recebidos Acumuladamente - Art. 12-A da Lei no
                      7.713, de 1988 (sujeitos à tributação exclusiva)
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.tableRow,
                      {
                        width: "80%",
                      },
                    ]}
                  >
                    <View
                      style={[
                        styles.tableCol,
                        {
                          width: "50%",
                          borderRight: 1,
                        },
                      ]}
                    >
                      <Text style={styles.text}>6.1 Número do processo:</Text>
                    </View>
                    <View
                      style={[
                        styles.tableCol,
                        {
                          width: "40%",
                        },
                      ]}
                    >
                      <Text style={styles.text}>Quantidade de meses</Text>
                    </View>
                    <View
                      style={[
                        styles.tableCol,
                        {
                          width: "40%",
                          borderLeft: 1,
                          textAlign: "right",
                        },
                      ]}
                    >
                      <Text style={styles.text}>0,00</Text>
                    </View>
                  </View>

                  <View
                    style={[
                      styles.tableRow,
                      {
                        width: "80%",
                      },
                    ]}
                  >
                    <View
                      style={[
                        styles.tableCol,
                        {
                          width: "50%",
                        },
                      ]}
                    >
                      <Text style={styles.text}>
                        Natureza do rendimento: {register.naturezaRendimento}
                      </Text>
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    marginVertical: 2,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      marginTop: -10,
                    }}
                  >
                    <Text
                      style={[
                        styles.titleTableCol,
                        {
                          width: "20%",
                          textAlign: "right",
                        },
                      ]}
                    >
                      Valores em reais
                    </Text>
                  </View>
                  {rendimentoAcumuladamente.map((item, index) => (
                    <View key={index} style={styles.tableRow}>
                      <View
                        style={[styles.tableCol, { width: "80%", borderRight: 1 }]}
                      >
                        <Text style={styles.text}>{item.titulo}</Text>
                      </View>
                      <View
                        style={[
                          styles.tableCol,
                          { width: "20%", textAlign: "right" },
                        ]}
                      >
                        <Text style={styles.text}>
                          {register.rendimentoAcumuladamente[item.chave]}
                        </Text>
                      </View>
                    </View>
                  ))}
                </View>

                <View
                  style={{
                    marginVertical: 2,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={[
                        styles.titleTableCol,
                        {
                          width: "80%",
                          textAlign: "left",
                        },
                      ]}
                    >
                      7. Informações Complementares
                    </Text>
                  </View>
                  <View style={styles.textarea}>
                    {informacoesComplementares.map((info, index) => (
                      <Text
                        key={index}
                        style={[
                          styles.text,
                          {
                            marginBottom: 3,
                          },
                        ]}
                      >
                        {info}
                      </Text>
                    ))}
                  </View>
                </View>
              </View>
            </Page>

            <Page size="A4" style={styles.page}>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View
                    style={[
                      styles.tableCol,
                      {
                        width: "10%",
                      },
                    ]}
                  >
                    <Image style={styles.image} src={logo} />
                  </View>

                  <View
                    style={[
                      styles.tableCol,
                      {
                        width: "50%",
                        borderRight: 1,
                        justifyContent: "center",
                      },
                    ]}
                  >
                    <Text style={styles.title}>
                      MINISTÉRIO DA ECONOMIA {`\n`}
                      Secretaria Especial da Receita Federal do Brasil {`\n`}
                      Imposto sobre a Renda da Pessoa Física {`\n`}
                      Exercício de {register.exercise}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.tableCol,
                      {
                        width: "50%",
                        justifyContent: "center",
                      },
                    ]}
                  >
                    <Text style={styles.title}>
                      Comprovante de Rendimentos Pagos e de {`\n`}
                      Imposto sobre a Renda Retido na Fonte {`\n`} {`\n`}
                      Ano-calendário de {register.anoCalendario}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    marginVertical: 2,
                  }}
                >
                  <Text style={styles.titleTableCol}>
                    8. Responsável pelas Informações
                  </Text>
                  <View style={styles.tableRow}>
                    <View
                      style={[
                        styles.tableCol,
                        {
                          width: "40%",
                          borderRight: 1,
                        },
                      ]}
                    >
                      <Text style={styles.text}>Nome</Text>
                      <Text style={styles.text}>{register.responsavelNome}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableCol,
                        {
                          width: "20%",
                          borderRight: 1,
                        },
                      ]}
                    >
                      <Text style={styles.text}>Data</Text>
                      <Text style={styles.text}>{register.data}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableCol,
                        {
                          width: "auto",
                        },
                      ]}
                    >
                      <Text style={styles.text}>Assinatura</Text>
                    </View>
                  </View>
                  <Text
                    style={[
                      styles.titleTableCol,
                      {
                        marginTop: 2,
                      },
                    ]}
                  >
                    Aprovado pela Instrução Normativa RFB no 2.060, de 13 de dezembro
                    de 2021.
                  </Text>
                </View>
              </View>
            </Page>
          </>
        )
      })}

    </Document>
  );
};
