import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import {
    DropPaycheck,
    DropPaycheckIcon,
    FormActions,
    ListEmployees,
    UnregisteredEmployeesList,
} from "./styled";

import DashboardCardWrapper from "components/Dashboard/Cards/Wrapper";

import DashboardActions from "components/Dashboard/Actions";
import DashboardFormsCore from "../Core";
import DashboardFeedback from "components/Dashboard/Feedback";
import Drop from "components/Form/Drop";
import { toast } from "react-toastify";
import { capitalize, exposeStrapiError, normalizeStrapiRegister, numerize, yearOptions } from "utils";
import UploadFile from "components/Form/UploadFile";
import moment from "moment/moment";
import { Create, CreateAllImports, ReadRIncomes, Update } from "services/report-incomes";
import { UploadImage } from "services/api";
import { ReadEmployee } from "services/employee";
import { CoreContext } from "context/CoreContext";
import useEmployee from "hooks/useEmployee";
import { ConfirmationDialog } from "components/Modal";

export default function DashboardFormsRevenueAdd() {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const refForm = useRef()
    const { currentCompany, user } = useContext(CoreContext)
    const { createEmployee } = useEmployee()

    const actions = [
        { title: 'Cancelar', outline: true, action: () => history.goBack() },
        { title: 'Salvar', action: () => save(), loadable: true }
    ]

    const [loading, setLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [feedback, setFeedback] = useState(null)
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [unregisteredEmployees, setUnregisteredEmployees] = useState([]);
    const [showReplaceModal, setShowReplaceModal] = useState(false);

    const [formItems, setFormItems] = useState([])

    // const formItems = [
    //     { placeholder:"Ano", ref:"year", options:[], half:true },
    //     { placeholder:"Informe de rendimento", ref:"type", custom:<Drop title={"Adicionar arquivo"} />, half:true },
    // ]

    const maskCPF = (cpf) => {
        const digitsOnly = cpf.replace(/\D/g, '');
        return digitsOnly.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }

    const generateUniqueUploadId = () => {
        const date = new Date();
        const uniqueId = date.getTime().toString() + Math.floor(Math.random() * 1000).toString();
        return uniqueId;
    };

    const saveRevenue = async (item, values, uploadId) => {
        let filter = item?.cpf ? `?filters[cpf]=${maskCPF(item?.cpf)}` : `?filters[reg]=${item?.reg}`;
        let employeeResponse = await ReadEmployee(filter);
        let normalEmployee = employeeResponse?.data?.[0] ? normalizeStrapiRegister(employeeResponse?.data?.[0]) : null;

        if (!normalEmployee) {
            const createEmployeeResult = await createEmployee(
                item?.nome,
                `${item?.nome}@assepontoinforme.com.br`.replace(/ /g, ''),
                item?.cpf,
                item?.reg
            );

            if (!createEmployeeResult) {
                toast.error("Não foi possível registrar o funcionário. Verifique os dados e tente novamente.");
                return { status: 'error', message: "Não foi possível registrar o funcionário" };
            }

            // Tente recuperar novamente os dados do funcionário após a criação
            employeeResponse = await ReadEmployee(filter);
            normalEmployee = employeeResponse?.data?.[0] ? normalizeStrapiRegister(employeeResponse?.data?.[0]) : null;
        }

        // Se normalEmployee ainda é nulo após tentar criar, retorna
        if (!normalEmployee) {
            return { status: 'unregistered', item: { ...item, razaoSocial: item.razaoSocial || 'Não informado' } };
        }

        const checkPrev = await ReadRIncomes(currentCompany?.id, item?.cpf, capitalize(moment().format("MMMM/YYYY")), values['year'], normalEmployee?.id);

        if (checkPrev?.data?.length > 0) {
            setShowReplaceModal(true);
            return;
        }

        const payload = {
            cpf: item?.cpf,
            name: item?.nome,
            office: item?.funcionarioResumo?.funcao,
            data: item,
            year: values['year'],
            create_date: moment().toISOString(),
            employee: normalEmployee?.id,
            type: item?.rendimentoTributavel?.totalRendimentos,
            exercise: capitalize(moment().format("MMMM/YYYY")),
            company: currentCompany?.id,
            provents: parseFloat(`${item?.rendimentoTributavel?.totalRendimentos}`.replace(/\./g, '').replace(',', '.')),
            uploadId: uploadId,
            importingUser: user?.email,
        };

        const result = await Create({ data: payload });
        if (result && !exposeStrapiError(result)) {
            toast.success(`Informe de rendimento cadastrado com sucesso.`);
            return true;
        } else {
            toast.error("Houve um erro ao tentar registrar o informe de rendimento.");
            return false;
        }
    };
    


    const save = async (e) => {
        const values = refForm.current.getForm();
        if (!(values['year'])) {
            toast.error("Preencha todos os campos");
            return;
        }
        if (!selectedFile) {
            toast.error("Selecione o arquivo");
            return;
        }
    
        setLoading(true);
        const uploadId = generateUniqueUploadId();
    
        const result = await UploadImage(selectedFile, {
            type: "informerendimento",
            payload: {
                'CNPJ': `${currentCompany?.cnpj}`?.replace(/\.|\-|\//g, ''),
                'Ano Calendario': `${values['year']}`
            },
            companyId: currentCompany?.id
        });
    
        if (result && result.length > 0) {
            toast.success("Informes de rendimento processados e cadastrados com sucesso.");
            history.goBack();
        } else if (result.error) {
            toast.error(result.message);
        } else {
            toast.error("Erro ao processar o arquivo, verifique se os dados informados estão corretos.");
        }
    
        setLoading(false);
    };    

    const handleUnregisteredEmployees = (employees) => {
        setUnregisteredEmployees(employees);
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    const handleReplaceConfirm = async () => {
        const values = refForm.current.getForm();

        if (!selectedFile || !values['year']) {
            toast.warn("Selecione um arquivo e preencha todos os campos necessários.");
            return;
        }

        setLoading(true);

        // Processar o arquivo e extrair os dados necessários
        const result = await UploadImage(selectedFile, {
            type: "informerendimento",
            payload: {
                'CNPJ': `${currentCompany?.cnpj}`?.replace(/\.|\-|\//g, ''),
                'Ano Calendario': values['year']
            },
            companyId: currentCompany?.id
        });

        if (result.length) {
            console.log('process', result);
            const promises = result.map(item => {
                return saveRevenue(item, values).catch(error => {
                    console.error("Erro ao salvar o informe de rendimento:", error);
                    return null;
                });
            });

            await Promise.all(promises);

            toast.success("Informes de rendimento cadastrados com sucesso.");
            setShowReplaceModal(false);

        } else if (result.error) {
            toast.error(result.message);
        } else {
            toast.error("Erro ao processar o arquivo, verifique se os dados informados estão corretos.");
        }

        setLoading(false);
    };



    const ReplaceModalContent = () => (
        <>
            <p>Já existe um informe de rendimento lançado para este CPF e período. Deseja substituir o arquivo existente?</p>
            {selectedFile?.name ?
                <DropPaycheck onClick={() => setSelectedFile(null)}>{selectedFile?.name} <DropPaycheckIcon close /></DropPaycheck>
                :
                <UploadFile forwardRepo onChange={e => setSelectedFile(e)}>
                    <DropPaycheck><DropPaycheckIcon /> Adicionar arquivo </DropPaycheck>
                </UploadFile>}
        </>
    );

    const UnregisteredEmployeesContent = () => (
        <UnregisteredEmployeesList>
            {unregisteredEmployees.map((employee, index) => (
                <ListEmployees key={index}>
                    Nome: {employee.nome} <br />
                    CNPJ: {employee.cnpjCpf} <br />
                    CPF: {employee.cpf} <br />
                    Razão social: {employee.razaoSocial}
                </ListEmployees>
            ))}
        </UnregisteredEmployeesList>
    );

    useEffect(() => {
        setFormItems([
            { placeholder: "Ano", ref: "year", options: yearOptions, half: true },
            {
                placeholder: "Informe de rendimento",
                ref: "type",
                custom: selectedFile?.name ?
                    <DropPaycheck onClick={() => setSelectedFile(null)}>{selectedFile?.name} <DropPaycheckIcon close /></DropPaycheck>
                    :
                    <UploadFile forwardRepo onChange={e => setSelectedFile(e)}>
                        <DropPaycheck><DropPaycheckIcon /> Adicionar arquivo </DropPaycheck>
                    </UploadFile>,
                half: true
            }
        ])
    }, [selectedFile])

    return (
        <>
            <DashboardCardWrapper backable title={"Cadastrar informes"} white>
                {feedback ? <DashboardFeedback message={"Informes de rendimento cadastrados com sucesso"} type={"success"} /> : null}
                <DashboardFormsCore formItems={formItems} ref={refForm} />
                <FormActions>
                    <DashboardActions actions={actions} loading={loading} />
                </FormActions>
            </DashboardCardWrapper>

            <ConfirmationDialog
                open={isDialogOpen}
                title="Funcionários Não Cadastrados"
                message="Os seguintes funcionários não estão cadastrados e precisam de atenção:"
                onCancel={handleClose}
                BtnConfirm={false}
                children={<UnregisteredEmployeesContent />}
            />

            <ConfirmationDialog
                open={showReplaceModal}
                title="Substituir Informe de Rendimento"
                onCancel={() => setShowReplaceModal(false)}
                onConfirm={handleReplaceConfirm}
                children={<ReplaceModalContent />}
            />

        </>
    );
}