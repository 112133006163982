import { useContext, useEffect, useState } from "react";

import { } from "./styled";


import DashboardCardTabs from "components/Dashboard/Cards/Tabs";
import DashboardCardTable from "components/Dashboard/Cards/Table";
import { useHistory } from "react-router-dom";
import { CoreContext } from "context/CoreContext";
import { Read, ReadWithPagination } from "services/logs";
import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister } from "utils";
import { formateHourBR, parseDatestringUSBR } from "utils/parsers";
import { API_ENDPOINT } from "services/api";

export default function DashboardListsLogs() {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const { searchExpression, currentCompany } = useContext(CoreContext)

    const [activeTab, setActiveTab] = useState(0)

    const options = [
        { id: 1, title: "Ver" }
    ]

    const tabs = []

    const actions = [
        { title: 'Exportar', action: () => exportExcel() }
    ]

    const actionsOptions = {
        'created': 'Criou',
        'edited': 'Editou',
        'deleted': 'Deletou',
        'import': 'Importou',
    }

    const urlToTitleMap = {
        '/api/holerite-generate': 'Geração de Holerite',
        '/api/companies': 'Listagem de Empresas',
        '/api/company': 'Detalhes da Empresa',
        '/api/vacation-submissions': 'Submissão de Férias',
        '/api/import-holerites': 'Importação de Holerites',
        '/api/holerites': 'Listagem de Holerites',
        '/api/employees': 'Listagem de Funcionários',
        '/api/users': 'Listagem de Usuários',
        '/api/vacations': 'Gestão de Férias',
        '/api/report-incomes': 'Informe de rendimentos',
        '/api/holerites/generate-pdf' : 'Geração de PDF de Holerite',
        '/api/report-incomes/file' : 'Geração de PDF de Informe de rendimentos',
    };

    const columns = [
        { title: 'Data', ref: 'date' },
        { title: 'Hora', ref: 'hour' },
        { title: 'Usuário', ref: 'user' },
        { title: 'Procedimento', ref: 'type' },
        { title: 'Competência', ref: 'competence' },
        { title: 'Ação', ref: 'status' },
    ]

    const [rows, setRows] = useState([])

    const [loading, setLoading] = useState(false)

    const parseRegister = item => {

        // console.log(item)

        const user = normalizeStrapiRegister(item?.user);

        const getUrlTitle = (url) => {
            const dynamicUrlMatch = url.match(/^(\/api\/[a-z-]+)\/(\d+)$/);
            if (dynamicUrlMatch) {
                const basePart = dynamicUrlMatch[1];
                const idPart = dynamicUrlMatch[2];
                const baseTitle = urlToTitleMap[basePart];
                return baseTitle ? `${baseTitle} ID ${idPart}` : url;
            }
            return urlToTitleMap[url] || url;
        };

        const getActionTitle = (action, url) => {
            if (url === '/api/holerites' && action === 'created') {
                return actionsOptions['import'];
            }
            return actionsOptions[action];
        };

        return ({
            ...item,
            name: user?.name,
            date: parseDatestringUSBR(item?.createdAt),
            hour: formateHourBR(item?.createdAt),
            competence: parseDatestringUSBR(item?.createdAt).replace(/^\d{2}\//, ""),
            status: getActionTitle(item.action, item.url),
            type: getUrlTitle(item?.url),
            user: user?.email,
        });
    }

    const init = async () => {
        setLoading(true)
        const result = await Read(currentCompany?.id)

        if (result && !exposeStrapiError(result)) {
            const normalRegister = normalizeStrapiList(result) || []

            const promises = [...(new Array(result.meta.pagination.pageCount))].map((i, page) => {
                if (!(page + 1 < result.meta.pagination.pageCount)) return;
                return (
                    ReadWithPagination(currentCompany?.id, page + 2)
                )
            }).filter(f => f)

            const results = await Promise.all(promises)

            const resultReduce = results.map(normalizeStrapiList).reduce((p, c) => [...p, ...c], [])

            const sortedRegister = [...normalRegister, ...resultReduce].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

            setRows(sortedRegister.map(parseRegister))

        }
        setLoading(false)
    }


    const exportExcel = async () => {
        window.open(`${API_ENDPOINT}/download?filter={ "where": { "company": { "id": { "$eq":${currentCompany?.id} } } } }&omit=["createdAt", "updatedAt", "createdBy", "updatedBy", "id"]&table=logs`, 'Logs')
    }


    useEffect(() => {
        if (currentCompany?.id) { init(); }
    }, [currentCompany])

    return (
        <>
            <DashboardCardTabs title={"Histórico de atividades"} actions={actions} active={activeTab} setActive={setActiveTab} >
                {activeTab === 0 ? <DashboardCardTable rows={rows} columns={columns} searchExpression={searchExpression} pagination loading={loading} /> : null}
            </DashboardCardTabs>
        </>
    );
}