import { GET, POST, PUT, DELETE } from './api'

export const Create = async (params) => {
    return await POST(`/dashboards`, params, true);
}

export const Read = async (query = '') => {
    return await GET(`/dashboards${query}`, true);
}

export const ReadOne = async (id) => {
    return await GET(`/dashboards/${id}?populate=*`, true);
}

export const ReadByEmail = async (email) => {
    return await GET(`/dashboards?filters[email]=${email}`, true);
}

export const Update = async (params, id) => {
    return await PUT(`/dashboards/${id}`, params, true);
}

export const Delete = async (id) => {
    return await DELETE(`/dashboards/${id}`, true);
}

export const ReadEmployeeMonthCounts = async (year, companyId) => {
    return await GET(`/dashboard/count-employee?year=${year}&companyId=${companyId}`, true);
}

// count-employee?year=2024&companyId=1

export const ReadHoleriteMonthCounts = async (year) => {
    return await GET(`/dashboard/count-sum-liquid-salary?year=${year}`, true);
}
