import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { EmptyMessage, Load, LoadCenter } from 'ui/styled';
import { TableFooter, TablePagination } from '@mui/material';
import { tablePaginationClasses as classes } from '@mui/base/TablePagination';
import { useState } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.primary.main,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledFooterCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 'bold',
}));

const StyledFooterRow = styled(TableRow)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const CustomTablePagination = styled(TablePagination)`
  & .${classes.toolbar} {
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
  }

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.displayedRows} {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.spacer} {
    display: none;
  }

  & .${classes.actions} {
    display: flex;
    gap: 0.25rem;
  }
`;

export default function BasicTable({ columns, rows, loading, footer, showFooter = false, pagination = false }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const availableRowsPerPageOptions = [5, 10, 25, 50, 100].filter(option => rows.length > option);

  if (availableRowsPerPageOptions.length === 0 || !availableRowsPerPageOptions.includes(rowsPerPage)) {
    availableRowsPerPageOptions.push(rowsPerPage);
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 150 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((item, key) => <StyledTableCell key={key} align="left">{item.title}</StyledTableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              onClick={row.action ? row.action : null}
              style={row.action ? { cursor: "pointer" } : null}
            >
              {columns.map((item, key) => (
                <TableCell key={key} align="left">
                  {item?.['renderCell'] ? item.renderCell({ row }) : row?.[item.ref]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        {showFooter && (
          <TableFooter>
            <StyledFooterRow>
              <StyledFooterCell colSpan={3} align='left'>{footer.registration}</StyledFooterCell>
              <StyledFooterCell colSpan={2} align='left'>{footer.totalValue}</StyledFooterCell>
              <StyledFooterCell colSpan={2} align='left'></StyledFooterCell>
            </StyledFooterRow>
          </TableFooter>
        )}
      </Table>
      {pagination && rows.length > 5 && ( 
        <CustomTablePagination
          rowsPerPageOptions={availableRowsPerPageOptions}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage='Linhas por página'
        />
      )}
      {!loading ? (rows.length ? null : <EmptyMessage>Nenhum registro encontrado</EmptyMessage>) : <LoadCenter><Load /></LoadCenter>}
    </TableContainer>
  );
}