import { useContext, useEffect, useState } from "react";

import { } from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import DashboardCardHome from "components/Dashboard/Cards/Home";
import DashboardCardPaycheck from "components/Dashboard/Cards/Paycheck";
import { Read, ReadEmployeeMonthCounts, ReadHoleriteMonthCounts } from "services/dashboards";
import { Read as ReadEmployee } from "services/employee";
import { Read as ReadRequests } from "services/requests";
import { Read as ReadVacations } from "services/vacations";
import { Read as ReadEvents } from "services/event";
import { ReadAll as ReadHolerites } from "services/holerites";
import { exposeStrapiError, normalizeStrapiList } from "utils";
import { CoreContext } from "context/CoreContext";
import DashboardCardEmployee from "components/Dashboard/Cards/Employee/index.js";
import DashboardCardLastSignature from "components/Dashboard/Cards/LastSignature/index.js";

export default function DashboardHome() {

    const { user, currentCompany, setCurrentCompany } = useContext(CoreContext)

    // console.log('currentCompany', currentCompany)

    const [filterYear, setFilterYear] = useState(false)

    // console.log('filterYear', filterYear)

    const [loading, setLoading] = useState(false)
    const [register, setRegister] = useState(null)
    const [] = useState(null)

    const parseRegister = item => ({
        ...item
    })

    const init = async () => {
        setLoading(true);

        const employees = await ReadEmployee(currentCompany?.id) || { data: [] };
        const requests = await ReadRequests(currentCompany?.id) || { data: [] };
        const holerites = await ReadHolerites(currentCompany?.id) || { data: [] };
        const vacations = await ReadVacations(currentCompany?.id) || { data: [] };
        const events = await ReadEvents(currentCompany?.id) || { data: [] };
        const employeeMonthCounts = await ReadEmployeeMonthCounts(filterYear, currentCompany?.id) || { data: [] };
        const holeriteMonthCounts = await ReadHoleriteMonthCounts(filterYear) || { data: [] };

        // console.log('employeeMonthCounts', employeeMonthCounts);
        // console.log('holeriteMonthCounts', holeriteMonthCounts);

        const employeeCounts = Array.from({ length: 12 }, (_, index) => {
            const month = index + 1;
            const monthName = new Date(filterYear, index).toLocaleString('default', { month: 'long' });
            const count = employeeMonthCounts.data?.find(item => item.attributes.month === month)?.attributes.count || 0;
            return [monthName, parseInt(count)];
        });

        const holeriteCounts = Array.from({ length: 12 }, (_, index) => {
            const month = index + 1;
            const monthName = new Date(filterYear, index).toLocaleString('default', { month: 'long' });
            const totalLiquidSalary = holeriteMonthCounts.data?.find(item => parseInt(item.attributes.month) === month)?.attributes.totalLiquidSalary || 0;
            return [monthName, totalLiquidSalary];
        });

        // console.log('employees', employees);

        const allMonths = Array.from({ length: 12 }, (v, i) => {
            const date = new Date(new Date().getFullYear(), i);
            return `${date.getMonth() + 1}-${date.getFullYear()}`;
        });

        const totalHoleritesSum = allMonths.map(month => {
            const [m, year] = month.split('-');
            const monthName = new Date(year, m - 1).toLocaleString('en-US', { month: 'long' });

            const monthRef = monthName.toLowerCase();

            return {
                month: monthRef,
                total: holeriteCounts.find(hc => hc[0].toLowerCase() === monthRef)?.[1] || 0
            };
        });

        const extractedData = holerites.data.map(hol => ({
            isSigned: hol.attributes.isSigned,
            signedAt: hol.attributes.signedAt,
            name: hol.attributes.name
        }));

        const result = await Read(`?filters[company]=${currentCompany?.id}&populate=*`);
        if (result && !exposeStrapiError(result)) {
            const normalRegister = normalizeStrapiList(result) || [];

            setRegister({
                ...normalRegister.map(parseRegister)?.[0],
                totalEmployees: employees?.data?.length,
                totalRefund: requests?.data?.length,
                totalSum: totalHoleritesSum,
                holeriteSignatures: extractedData,
                employeeMonthCounts: employeeCounts,
                holeriteMonthCounts: holeriteCounts,
                events: {
                    data: (events?.data || []).sort((a, b) => a.createdAt - b.createdAt).filter(f => f?.attributes?.is_dashboard)
                },
                employees: {
                    data: (vacations?.data || []).sort((a, b) => a.createdAt - b.createdAt).slice(0, 5)
                },
                requests: {
                    data: (requests?.data || []).filter(f => user?.isManager || f?.attributes?.cpf === user?.cpf).sort((a, b) => new Date(b?.attributes?.createdAt).getTime() - new Date(a?.attributes?.createdAt).getTime()).slice(0, 5)
                }
            });
        }
        setLoading(false);
    }






    useEffect(() => {
        if (currentCompany?.id) { init(); }
        // console.log("filterYear", filterYear)
    }, [currentCompany, filterYear])

    return (
        <>
            <ContainerAuthenticated page={"home"}>
                {
                    !user?.isManager ? null :
                        <Row>
                            <Col sm={12} md={12}>
                                <DashboardCardHome register={register} />
                                <DashboardCardPaycheck loading={loading} register={register} filterYear={setFilterYear} />
                            </Col>
                        </Row>
                }
                <Row>
                    {
                        !user?.isManager ? null :
                            <Col sm={12} md={6}>
                                {/* <DashboardCardEmployeeVacation register={register} loading={loading} /> */}
                                <DashboardCardEmployee loading={loading} register={register} filterYear={setFilterYear} />
                            </Col>
                    }
                    {/* <Col sm={12} md={!user?.isManager ? 12 : 6}>
                        <DashboardCardLastSolicitations register={register} loading={loading} />
                    </Col> */}
                    {
                        !user?.isManager ? null :
                            <Col sm={12} md={!user?.isManager ? 12 : 6}>
                                <DashboardCardLastSignature register={register} loading={loading} />
                            </Col>
                    }
                </Row>
            </ContainerAuthenticated>
        </>
    );
}