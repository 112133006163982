import styled from 'styled-components'  



export const CardHomeStatus = styled.div.attrs({ 
})`
    display:flex;
    align-items: center;
    gap: 8px 1%;
    padding: 16px 0 0;
    flex-wrap:wrap;
`;
export const CardHomeStatuItem = styled.div.attrs({ 
})`
    flex:1;
    padding: 16px;
    border-radius: 16px;
    box-shadow: 0px 1px 3px ${ props => props.theme.palette.colors.lightshadow };
    background: ${ props => props.theme.palette.colors.white }; 
    border: 1px solid ${ props => props.theme.palette.colors.lightshadow };
    b{
        margin: 0;
        font-size: 14px;
        font-weight: bold;
        color: ${ props => props.theme.palette.primary.main }; 
        min-height: 41px;
        display:flex;
        align-items: center;
        ${ props => props.limited ? `max-width: 55px;` : `` }
    }
    p{
        margin: 6px 0 0;
        font-size: 18px;
        font-weight: bold;
        color: ${ props => props.theme.palette.primary.text }; 
    }
`;