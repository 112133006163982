import { GET, POST, PUT, DELETE } from './api'

export const Create = async (params) => {
    return await POST(`/logs`, params, true);
}

export const Read = async (id) => {
    return await GET(`/logs?filters[company]=${id}&_limit=-1&populate=*&pagination[pageSize]=1000`, true);
}

export const ReadWithPagination = async (id, page) => {
    return await GET(`/logs?filters[company]=${id}&_limit=-1&populate=*&pagination[pageSize]=1000&pagination[page]=${page}`, true);
}

export const ReadOne = async (id) => {
    return await GET(`/logs/${id}?populate=*`, true);
}

export const Update = async (params, id) => {
    return await PUT(`/logs/${id}`, params, true);
}

export const Delete = async (id) => {
    return await DELETE(`/logs/${ id }`, true);
} 
 