import { useContext } from "react";

import { } from "./styled";

import DashboardCardTable from "../Table";
import { CoreContext } from "context/CoreContext";
import moment from "moment";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export default function DashboardCardLastSignature({ register, loading }) {   
    const { searchExpression } = useContext(CoreContext);

    const rows = register?.holeriteSignatures?.length ? register?.holeriteSignatures?.filter(hol => hol.isSigned).sort((a, b) => new Date(a.signedAt) - new Date(b.signedAt)).map(hol => {

        const isSigned = hol?.isSigned === true;

        return {
            name: hol.name,
            isSigned: isSigned ? <CheckIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />,
            signedAt: moment(hol.signedAt).format("DD/MM/YYYY HH:mm"),
        }
    }) : [];

    const columns = [
        { title: 'Nome do funcionário', ref: 'name' },
        { title: 'Assinado', ref: 'isSigned' },
        { title: 'Data e Hora da Assinatura', ref: 'signedAt' }
    ];

    return (
        <>
            <DashboardCardTable title={"Assinaturas"} rows={rows} columns={columns} searchExpression={searchExpression} loading={loading} />
        </>
    );
}


