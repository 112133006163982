import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Landpage from 'screens/Landpage' 
import NotFound from 'screens/NotFound' 

import Login from 'screens/Authentication/Login' 
import Register from 'screens/Authentication/Register' 
import Forgot from 'screens/Authentication/Forgot' 
import CreatePassword from 'screens/Authentication/CreatePassword' 

import DashboardHome from 'screens/Dashboard/Home' 
import DashboardMe from 'screens/Dashboard/Me' 

import DashboardListPaycheck from "screens/Dashboard/List/Paycheck";
import DashboardFormPaycheck from "screens/Dashboard/Form/Paycheck";
import DashboardFormPaycheckAdd from "screens/Dashboard/Form/PaycheckAdd";

import DashboardFormConfig from "screens/Dashboard/Form/Config";

import DashboardListRevenues from "screens/Dashboard/List/Revenues";
import DashboardFormRevenueAdd from "screens/Dashboard/Form/RevenueAdd";

import DashboardListUsers from "screens/Dashboard/List/Users";
import DashboardListEmployee from "screens/Dashboard/List/Employee";
import DashboardListEvents from "screens/Dashboard/List/Events";
import DashboardListHolidays from "screens/Dashboard/List/Holidays";
import DashboardListLogs from "screens/Dashboard/List/Logs";
import DashboardListVacantion from "screens/Dashboard/List/Vacantion";
import DashboardListSolicitation from "screens/Dashboard/List/Solicitation";

import DashboardFormEvent from "screens/Dashboard/Form/Event";
import DashboardFormHoliday from "screens/Dashboard/Form/Holiday";
import DashboardFormVacantion from "screens/Dashboard/Form/Vacantion";
import DashboardFormSolicitation from "screens/Dashboard/Form/Solicitation";
import DashboardFormSolicitationRefund from "screens/Dashboard/Form/SolicitationRefund";
import DashboardFormUsers from "screens/Dashboard/Form/Users";
import DashboardFormEmployee from "screens/Dashboard/Form/Employee";
import DashboardFormPaycheckUser from "screens/Dashboard/Form/PaycheckUser";
import DashboardListLookerStudio from "screens/Dashboard/List/LookerStudio";
import DashboardFormRevenue from "screens/Dashboard/Form/Revenue/index.js";

export default function AppRouter() {
    return (
      <Router>  
        <div>
          <Switch>
            {/* <Route path="/" exact> <Landpage /> </Route>  */}
            
            <Route path="/" exact> <Login /> </Route> 
            <Route path="/login" exact> <Login /> </Route> 
            <Route path="/register" exact> <Register /> </Route> 
            <Route path="/forgot" exact> <Forgot /> </Route> 
            <Route path="/create-password" exact> <CreatePassword /> </Route> 
            
            <Route path="/dashboard" exact> <DashboardHome /> </Route> 

            <Route path="/dashboard/config" exact> <DashboardFormConfig /> </Route> 

            <Route path="/dashboard/paycheck" exact> <DashboardListPaycheck /> </Route> 
            <Route path="/dashboard/paycheck/form/:id" exact> <DashboardFormPaycheck /> </Route> 
            <Route path="/dashboard/paycheck/add" exact> <DashboardFormPaycheckAdd /> </Route> 
            <Route path="/dashboard/paycheck/add/:id" exact> <DashboardFormPaycheckAdd /> </Route> 


            <Route path="/dashboard/paycheck/form" exact> <DashboardFormPaycheck /> </Route> 
            <Route path="/dashboard/paycheck/user/:id" exact> <DashboardFormPaycheckUser /> </Route> 
            
            <Route path="/dashboard/revenues" exact> <DashboardListRevenues /> </Route> 
            <Route path="/dashboard/revenues/form/:id" exact> <DashboardFormRevenue /> </Route> 
            <Route path="/dashboard/revenues/add" exact> <DashboardFormRevenueAdd /> </Route> 
            <Route path="/dashboard/revenues/add/:id" exact> <DashboardFormRevenueAdd /> </Route> 
            
            <Route path="/dashboard/solicitation" exact> <DashboardListSolicitation /> </Route> 
            <Route path="/dashboard/solicitation/vacantion" exact> <DashboardFormSolicitation /> </Route> 
            <Route path="/dashboard/solicitation/vacantion/:id" exact> <DashboardFormSolicitation /> </Route> 
            <Route path="/dashboard/solicitation/refund" exact> <DashboardFormSolicitationRefund /> </Route> 
            <Route path="/dashboard/solicitation/refund/:id" exact> <DashboardFormSolicitationRefund /> </Route> 
            
            <Route path="/dashboard/vacantion" exact> <DashboardListVacantion /> </Route> 
            <Route path="/dashboard/vacantion/add" exact> <DashboardFormVacantion /> </Route> 
            <Route path="/dashboard/vacantion/add/:id" exact> <DashboardFormVacantion /> </Route> 

            <Route path="/dashboard/employee" exact> <DashboardListEmployee /> </Route> 
            <Route path="/dashboard/employee/add" exact> <DashboardFormEmployee /> </Route> 
            <Route path="/dashboard/employee/add/:id" exact> <DashboardFormEmployee /> </Route> 
            
            <Route path="/dashboard/users" exact> <DashboardListUsers /> </Route> 
            <Route path="/dashboard/users/add" exact> <DashboardFormUsers /> </Route> 
            <Route path="/dashboard/users/add/:id" exact> <DashboardFormUsers /> </Route> 
            
            <Route path="/dashboard/events" exact> <DashboardListEvents /> </Route> 
            <Route path="/dashboard/events/add" exact> <DashboardFormEvent /> </Route> 
            <Route path="/dashboard/events/add/:id" exact> <DashboardFormEvent /> </Route> 
            
            <Route path="/dashboard/holidays" exact> <DashboardListHolidays /> </Route> 
            <Route path="/dashboard/holidays/add" exact> <DashboardFormHoliday /> </Route> 
            <Route path="/dashboard/holidays/add/:id" exact> <DashboardFormHoliday /> </Route> 

            <Route path="/dashboard/logs" exact> <DashboardListLogs /> </Route> 
            <Route path="/dashboard/looker-studio" exact> <DashboardListLookerStudio /> </Route> 

            <Route path="/dashboard/me" exact> <DashboardMe /> </Route> 
            
            <Route path="*" exact> <NotFound /> </Route>
          </Switch>
        </div>
      </Router>
    );
}