import React from "react";

import {
    CardHomeChart
} from "./styled";

import { Chart } from "react-google-charts";

import { monthsOptions } from "utils/brazil";
import DashboardCardWrapper from "../Wrapper";
import { theme } from "ui/theme-color";
import { yearOptions } from "utils";
import { Load, LoadCenter } from "ui/styled";

export default function DashboardCardEmployee({ register, loading }) {

    // console.log('register', register)

    const data = [
        ["Mês", "Total de Funcionários"],
        ...(register?.employeeMonthCounts || [])
    ];

    const options = {
        curveType: "function",
        legend: { position: "none" },
        colors: [theme.palette.primary.main],
        backgroundColor: theme.palette.colors.backgroundColor,
        tooltip: { trigger: 'selection' },
    };

    return !register?.employeeMonthCounts?.length ? null : (
        <>
            <DashboardCardWrapper title={"Quantidade de Funcionários por Mês"}>
                <CardHomeChart>
                    {loading ? <LoadCenter><Load /></LoadCenter> :
                        <Chart
                            chartType="Bar"
                            width="100%"
                            height="300px"
                            data={data}
                            options={options}
                        />}
                </CardHomeChart>
            </DashboardCardWrapper>
        </>
    );
}
