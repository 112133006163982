import React, { useState } from "react";  

import {  
    
    CardHomeHeaderActions,
    HeaderActions,

} from "./styled";

import Button from "components/Form/Button";

export default function DashboardActions({ actions, loading }){  

    return ( 
        <>
            <HeaderActions>
                {
                    actions?.map((item, key) => 
                        <CardHomeHeaderActions key={key}>
                            <Button outline={!!item.outline} primary onClick={item.action} loading={item.loadable && loading}>{ item.title }</Button>
                        </CardHomeHeaderActions>
                    )
                }
            </HeaderActions>
        </>
    );
}