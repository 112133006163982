import { toast } from 'react-toastify';
import { API_ENDPOINT } from 'services/api'

export const exposeStrapiError = result => { 

    if( !result ){
        toast.error( 'Cant connect to server, try again later')
        return true;
    }
    
    if(result?.error && result?.data?.[0]?.messages?.[0]?.message && typeof result?.data?.[0]?.messages?.[0]?.message === 'string'){
        toast.error(result?.data?.[0]?.messages?.[0]?.message )
        return true;
    }else if(result?.error && result?.message?.[0]?.messages?.[0]?.message && typeof result?.message?.[0]?.messages?.[0]?.message === 'string'){
        toast.error(result?.message?.[0]?.messages?.[0]?.message )
        return true;
    }else if(result?.error && result?.data?.[0]?.message && typeof result?.data?.[0]?.message === 'string'){
        toast.error(result?.data?.[0]?.message )
        return true;
    }else if(result?.error && result?.message && typeof result?.message === 'string'){
        toast.error(result?.message )
        return true;
    }else if(result?.error && result?.error?.message && typeof result?.error?.message === 'string'){
        toast.error(result?.error?.message )
        return true;
    }

    if(result?.error && result?.error?.details?.errors?.length ){
        result?.error?.details?.errors?.map( item => {
            toast.error(item?.message )
            return true;
        })
        return true;
    }
    
    return false;
}
 
export const normalizeStrapiList = result => {
    return result?.data?.length ? result?.data.map(item => ({
        id: item.id,
        ...item.attributes
    })) : result?.data || [] 
}
 
export const normalizeStrapiRegisterSolo = result => { 
    return result?.id && result?.attributes ? { 
        id: result?.id,  
        ...result?.attributes
    } : result?.data?.id ? result?.data?.attributes ? {
        id: result?.data?.id,  
        ...result?.data?.attributes
    } : result?.data : result
}

export const normalizeStrapiRegister = result => { 
    let results = normalizeStrapiRegisterSolo(result)  
    if(!results){ return [] ;}
    Object.keys(results).map(attrKey => {
        results[attrKey] = results[attrKey]?.data ? normalizeStrapiRegisterSolo(results[attrKey]) : results[attrKey]
        results[attrKey] = typeof results[attrKey]?.data === 'object' ? results[attrKey]?.data : results[attrKey]
        results[attrKey] = results[attrKey]?.length && results[attrKey]?.[0]?.attributes ? 
            results[attrKey]?.map(normalizeStrapiRegisterSolo)
        : results[attrKey] 


        return true;
    }) 
    return results;
}
 
export const normalizeStrapiPayload = (form, formtypes) => {

    formtypes.map(item => {
        if(form[item.name]){ 
            if((item.type === 'float' || item.type === 'decimal') && item.name === 'price' && typeof form[item.name] === 'string' ){
                form[item.name] = parseFloat(form[item.name].replace(/R|\$|\.| /g, '').replace(',','.'))
            }
    
            if( item.type === 'float' || item.type === 'decimal' ){
                form[item.name] = parseFloat(form[item.name])
            }
    
            if( item.type === 'biginteger' || item.type === 'integer' ){
                form[item.name] = parseInt(form[item.name])
            }
            
            if( (item.type === 'date' || item.type === 'time' || item.type === 'datetime') && typeof form[item.name]?.getFullYear === 'function' ){
                form[item.name] = parseStrapiDate(form[item.name])
            }
            
            if( item.type === 'time' ){
                form[item.name] = (`${form[item.name]}`.split("T")).pop() 
            }
            
            if( item.type === 'date' ){
                form[item.name] = `${(`${form[item.name]}`.split("T")).shift()}` 
            } 
            
        }
        return true;
    })

    return { ...form, data:{...form}}
}

export const numerize = number => {
    return parseInt(number) < 10 ? `0${number}` : `${number}`
}

export const parseStrapiDate = date => {  
    return typeof date?.getFullYear !== 'function' ? date : `${date.getFullYear()}-${numerize(date?.getMonth()+1)}-${numerize(date?.getDate())}T${numerize(date?.getHours())}:${numerize(date?.getMinutes())}:${numerize(date?.getSeconds())}`
} 
 
export const parseStrapiImage = url => {
    return url?.indexOf('://') !== -1 ? url : `${API_ENDPOINT.replace('.br/api', '.br')}${url}`
}

export const findShowableParam = item => {
    
    const notAllowed = [ 
        'id',
        'createdAt',
        'updatedAt',
        'createdBy',
        'updatedBy',
        'publishedAt',
        'attributes',
        'created_by',
        'updated_by',
        'created_at',
        'updated_at',
        'published_by',
        'published_at',
    ]
    return Object.keys(item).filter(fit => !notAllowed.includes(fit) ).shift()
}

export const filterSystemParams = (fit, attrs, item) => {

    const notAllowed = [ 
        'id',
        'createdAt',
        'updatedAt',
        'createdBy',
        'updatedBy',
        'publishedAt',
        'created_by',
        'updated_by',
        'created_at',
        'updated_at',
        'published_by',
        'published_at',
    ]

    if(item.uid.indexOf('plugin::') !== -1){
        notAllowed.push('provider')
        notAllowed.push('role')
    } 

    return !notAllowed.includes(fit) && (!attrs.private || attrs.type === 'password')
} 

export const capitalize = (s) => {
    if(!s){ return `` ;}
    let vs = `${s}`
    return `${ vs.charAt(0).toUpperCase() }${vs.slice(1)}`;
}

export const booleanOptions = [
   { id:'true', title:"Sim" },
   { id:'false', title:"Não" },
]

export const repeatOptions = [
   { id:'one', title:"Um vez" },
   { id:'always', title:"Sempre" },
]

export const tributeOptions = [
   { id:'mei', title:"MEI" },
   { id:'me', title:"ME" },
]

export const typeSheetOptions = [
   { id:'1', title:"Holerite Prosoft" },
   { id:'2', title:"Holerite Dominio TXT" },
   { id:'3', title:"Holerite SCI" },
   { id:'4', title:"HoleriteContimatic" },
   { id:'5', title:"Holerite Sage" },
   { id:'6', title:"Holerite Master Maq NG" },
   { id:'7', title:"Holerite Folhamatic Sage" },
   { id:'8', title:"Holerite Questor" },
   { id:'9', title:"Holerite Contimatic Phoenix" },
   { id:'10', title:"Holerite Alterdata" },
   { id:'11', title:"Holerite Totvs" },
   { id:'12', title:"Holerite Londrisoft" },
   { id:'13', title:"Holerite Alterdata Novo" },
   { id:'14', title:"Doméstica" },
   { id:'15', title:"Holerite Dominio EXCEL" },
   { id:'16', title:"Holerite SCI Novo" },
   { id:'17', title:"Holerite SCI PDF" },
   { id:'18', title:"Holerite Prosoft Novo" },
   { id:'19', title:"Holerite Exactus Novo" },
   { id:'20', title:"Holerite Prosis Novo" },
]


export const plansOptions = [
   { id:'plan_1', title:"Regular" },
   { id:'plan_2', title:"Premium" },
]

export const paycheckTypeOptions = [
   { id:'refund', title:"Reembolso" },
   { id:'vacation', title:"Férias" },
]

export const paymentMethodOptions = [
    { id:'pix', title:"PIX" },
    { id:'ted', title:"TED" },
    { id:'doc', title:"DOC" },
 ]
 
const currentYear = (new Date()).getFullYear()

export const yearOptions = [...Array(currentYear -  2021)].map((m,k) => ({ id:`${ currentYear - k }`, title:`${ currentYear - k }` }))

export const typePaycheckOptions = [
   { id:'1', title:"Recibo de Salário" },
   { id:'2', title:"Recibo de Pró Labore" },
   { id:'3', title:"Recibo de Adiantamento" },
   { id:'4', title:"Recibo de Primeira Parcela 13º Salário" },
   { id:'5', title:"Recibo de Segunda Parcela 13º Salário" },
   { id:'6', title:"Participação Remunerada nos Resultados" },
   { id:'7', title:"Recibo de Abono" },
   { id:'8', title:"E-SOCIAL" },
]

export const isSignedOptions = [
    { id:'1', title:"Sim" },
    { id:'2', title:"Não" },
]


export const statusOptions = [
   { id:'new', title:"Pendente" },
   { id:'approved', title:"Aprovado" },
   { id:'denied', title:"Recusado" },
]