import React, { useContext, useEffect, useState } from "react";   
 
import DashboardSide from "../Side";
import { 
    DashboardHeaderContainer,
    DashboardHeaderAction,
    DashboardHeaderActionIcon,
    HeaderSelect,
    HeaderSelectText,
    HeaderSelectIcon,
    HeaderSelectCog,
    HeaderInputContent,
    HeaderSelectContent,
    HeaderSelectBody
} from "./styled"; 
import Input from "components/Form/Input";
import { useHistory } from "react-router-dom";
import { CoreContext } from "context/CoreContext";
import { ReadMe } from "services/authentication";
import { Update } from "services/users";
import { Load } from "ui/styled";

export default function DashboardHeader(){  
    
    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 
    const { searchExpression, setSearchExpression, user, companies, setCompanies, currentCompany, setCurrentCompany } = useContext(CoreContext)
    const [opened, setOpened] = useState(false) 
    const [loading, setLoading] = useState(false) 

    const init = async () => {
        setLoading(true)
        const result = await ReadMe()
        if(result?.id){
            if(result?.companies?.length){ 
                setCompanies(result?.companies) ;
                setCurrentCompany( result?.currentCompany?.id ? result?.currentCompany : ( result?.companies?.[0] || null ))
            }
        }
        setLoading(false)
    }

    const selectCompany = async item => {
        setOpened(false)
        setLoading(true)
        const result = await Update({  
            currentCompany: item?.id 
        }, user?.id)

        console.log("update result", result)
        setCurrentCompany( item )
        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])


    return ( 
        <> 
            <DashboardHeaderContainer>
                {/* <DashboardHeaderAction onClick={() => setOpened(true)}>
                    <DashboardHeaderActionIcon src={`/images/logo.png`} alt="logo-icon" /> 
                </DashboardHeaderAction>   */}

                <HeaderSelectContent>
                    <HeaderSelect onClick={() => setOpened(!opened)}>
                        {
                            loading ? <Load white /> : <>
                                <HeaderSelectText>{ currentCompany?.fantasyName }</HeaderSelectText>
                                <HeaderSelectIcon opened={opened} />
                            </>
                        }
                    </HeaderSelect>
                    {
                        !opened ? null : 
                        <HeaderSelectBody>
                            {
                                companies?.map((item, key) => 
                                    <HeaderSelect item key={key} onClick={() => selectCompany(item)}>
                                        <HeaderSelectText item>{ item?.fantasyName }</HeaderSelectText>
                                    </HeaderSelect>
                                )
                            }
                        </HeaderSelectBody>
                    }
                </HeaderSelectContent>


                <HeaderInputContent>
                    <Input placeholder="Pesquisar" value={searchExpression} onChange={e => setSearchExpression(e.target.value)} />
                </HeaderInputContent>
                {
                    !user?.isManager ? null :
                    <HeaderSelect onClick={() => navigate("dashboard/config")}>
                        <HeaderSelectCog />
                    </HeaderSelect>
                }
            </DashboardHeaderContainer> 
        </>
    );
}