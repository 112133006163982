import React from "react";  

import {  
    
    CardHomeStatus,
    CardHomeStatuItem

} from "./styled";

import { monthsOptions } from "utils/brazil";
import DashboardCardWrapper from "../Wrapper";
import { normalizeStrapiRegister } from "utils";

export default function DashboardCardHome({ register }){  

    console.log('register?.events',register?.events)

    
    const cards = [
        { title:"Qtd. de Funcionários", value:register?.totalEmployees || "" },
        // { title:"Funcionários afastados", value:register?.totalEmployeesDisable || "" },
        // { title:"Total de faltas", value:register?.totalFails || "", limited:true },
        // { title:"Total de Horas extras", value:register?.totalExtraHour || "" },
        // { title:"Total de atrasos", value:register?.totalDelayed || "", limited:true },

        ...(register?.events?.data||[])?.map(m => {
        
            const reg = normalizeStrapiRegister(m)
            
            return ({
                title: reg?.name,
                value: reg?.codes?.length
            })
        }),

        { title:"Solicitações", value:register?.totalRefund || "" },
    ]

    return ( 
        <>
            <DashboardCardWrapper title={"Dashboard - por quantidades de funcionários"} selectable={true ? null : { options:monthsOptions, placeholder:"Mês" }}>
                <CardHomeStatus>
                    {
                        cards.map((item, key) => 
                            <CardHomeStatuItem key={key} limited={item.limited}>
                                <b>{ item.title }</b>
                                <p>{ item.value }</p>
                            </CardHomeStatuItem>
                        )
                    }
                </CardHomeStatus>
            </DashboardCardWrapper>
        </>
    );
}