import React, { useContext, useEffect, useState } from "react";

import {

} from "./styled";


import DashboardCardTabs from "components/Dashboard/Cards/Tabs";
import DashboardCardTable from "components/Dashboard/Cards/Table";
import { useHistory } from "react-router-dom";
import { CoreContext } from "context/CoreContext";
import { exposeStrapiError, normalizeStrapiList, paycheckTypeOptions, typePaycheckOptions, typeSheetOptions } from "utils";
import { Read, ReadAll, ReadAllForEmployee, ReadAllImports } from "services/holerites";
import { parseDatestringUSBR } from "utils/parsers";
import Accordion from "components/Accordion";
import BasicTable from "components/Form/Table";
import moment from "moment";
import { ReadEmployeeByUserId } from "services/employee.js";


export default function DashboardListPaycheck() {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const [activeTab, setActiveTab] = useState(0)
    const [uploadsRegister, setUploadsRegister] = useState([])
    const { searchExpression, currentCompany, user } = useContext(CoreContext)

    const options = [
        { id: 1, title: "Ver" }
    ]

    const tabs = [
        { title: 'Cadastros' },
        // { title:'Erros', badge:2 }
    ]

    const actions = [
        // { title:'Cancelar', outline:true, action:() => history.goBack() },
        ...(user?.isManager ?  [{ title: 'Novo', action: () => navigate("dashboard/paycheck/add") }] : [])
    ]

    const columns = [
        { title: 'Tipo de folha', ref: 'sheetType' },
        { title: 'Ano', ref: 'year' },
        { title: 'Mês', ref: 'month' },
        { title: 'Tipo de holerite', ref: 'type' },
        ...(user?.isManager ? [
            { title: 'Data e hora da criação', ref: 'create_date' },
            { title: 'Usuario que importou', ref: 'importingUser' }
        ] : [])
    ]

    const columnsBody = [
        { title: 'Nome', ref: 'name' },
        { title: 'E-mail', ref: 'email' },
    ]

    const actionEdit = () => {
        navigate("dashboard/paycheck/form")
    }
    const [rows, setRows] = useState([])

    const columnsTab1 = [
        { title: 'CPF', ref: 'document' },
        { title: 'Nome do funcionário', ref: 'name' },
        { title: 'Ano / Mês', ref: 'date' },
        { title: 'Tipo de holerite', ref: 'type' },
        { title: 'Erro', ref: 'error' }
    ]

    const [rowsTab1, setRowsTab1] = useState([
        { document: "888.888.888-88", name: "Mariana da Fonseca da Silva", date: "2023 / Agosto", type: "Salário", error: "Texto do erro" },
        { document: "888.888.888-88", name: "Mariana da Fonseca da Silva", date: "2023 / Agosto", type: "Salário", error: "Texto do erro" },
        { document: "888.888.888-88", name: "Mariana da Fonseca da Silva", date: "2023 / Agosto", type: "Salário", error: "Texto do erro" },
        { document: "888.888.888-88", name: "Mariana da Fonseca da Silva", date: "2023 / Agosto", type: "Salário", error: "Texto do erro" },
        { document: "888.888.888-88", name: "Mariana da Fonseca da Silva", date: "2023 / Agosto", type: "Salário", error: "Texto do erro" },
        { document: "888.888.888-88", name: "Mariana da Fonseca da Silva", date: "2023 / Agosto", type: "Salário", error: "Texto do erro" },
        { document: "888.888.888-88", name: "Mariana da Fonseca da Silva", date: "2023 / Agosto", type: "Salário", error: "Texto do erro" },
        { document: "888.888.888-88", name: "Mariana da Fonseca da Silva", date: "2023 / Agosto", type: "Salário", error: "Texto do erro" },
        { document: "888.888.888-88", name: "Mariana da Fonseca da Silva", date: "2023 / Agosto", type: "Salário", error: "Texto do erro" },
        { document: "888.888.888-88", name: "Mariana da Fonseca da Silva", date: "2023 / Agosto", type: "Salário", error: "Texto do erro" },
        { document: "888.888.888-88", name: "Mariana da Fonseca da Silva", date: "2023 / Agosto", type: "Salário", error: "Texto do erro" },
    ])

    const [loading, setLoading] = useState(false)

    const parseRegister = item => {
        console.log('Item processado:', item);
        return {
            ...item,
            date: parseDatestringUSBR(item.createdAt),
            action: () => user?.isManager ? navigate(`dashboard/paycheck/form/${item.uploadId}`) : navigate(`dashboard/paycheck/form/${item.id}`),
            uploadId: item.uploadId,
            id: item.id,
            companyId: currentCompany?.id
        };
    };


    const init = async () => {
        setLoading(true);
        if (user?.isManager) {
            const holeriteResult = await ReadAll(currentCompany?.id);
            if (holeriteResult && !exposeStrapiError(holeriteResult)) {
                const normalHoleriteRegister = normalizeStrapiList(holeriteResult) || [];

                const groupedByUploadId = normalHoleriteRegister.reduce((acc, item) => {
                    if (!acc[item.uploadId]) {
                        acc[item.uploadId] = item;
                    }
                    return acc;
                }, {});

                const consolidatedHoleriteRegisters = Object.values(groupedByUploadId).map(parseRegister).map(register => {
                    register.create_date = moment(register.create_date).format("DD/MM/YYYY HH:mm");
                    register.type = typePaycheckOptions.find(option => option.id === register.type)?.title;
                    register.sheetType = typeSheetOptions.find(option => option.id === register.sheetType)?.title;
                    return register;
                });

                setRows(consolidatedHoleriteRegisters);
            }
        } else {
            const result = await ReadEmployeeByUserId(user?.id);
            const employeeId = result.data[0]?.id
            const holeriteResult = await ReadAllForEmployee(employeeId);

            if (holeriteResult && !exposeStrapiError(holeriteResult)) {
                const normalHoleriteRegister = normalizeStrapiList(holeriteResult) || [];

                const consolidatedHoleriteRegisters = Object.values(normalHoleriteRegister).map(parseRegister).map(register => {
                    register.create_date = moment(register.create_date).format("DD/MM/YYYY HH:mm");
                    register.type = typePaycheckOptions.find(option => option.id === register.type)?.title;
                    register.sheetType = typeSheetOptions.find(option => option.id === register.sheetType)?.title;
                    return register;
                });

                setRows(consolidatedHoleriteRegisters);
            }
        }

        setLoading(false);
    };

    useEffect(() => {
        if (currentCompany?.id) { init(); }
    }, [currentCompany])


    return (
        <>
            <DashboardCardTabs title={"Holerites"} actions={actions} tabs={tabs} active={activeTab} setActive={setActiveTab} selectable={{ options, placeholder: "Ações" }} >
                {activeTab === 0 ? <DashboardCardTable rows={rows} columns={columns} searchExpression={searchExpression} loading={loading} /> : null}
            </DashboardCardTabs>
        </>
    );
}