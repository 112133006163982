import React, { useContext, useEffect, useRef, useState } from "react";  
import { useHistory } from "react-router-dom";

import { 
    FormActions, 
} from "./styled";


import DashboardActions from "components/Dashboard/Actions";
import DashboardCardWrapper from "components/Dashboard/Cards/Wrapper";
import DashboardFormsCore from "../Core";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { CoreContext } from "context/CoreContext";
import { exposeStrapiError, normalizeStrapiRegister, plansOptions, tributeOptions, typeSheetOptions } from "utils";
import { toast } from "react-toastify";
import { Create, ReadOne, Update } from "services/companies";
import { ReadAddressesByZipCode } from "services/api";
import DashboardCardLimit from "components/Dashboard/Cards/Limit";

export default function DashboardFormsConfig(){   

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 
    
    const { currentCompany } = useContext(CoreContext)

    const { id } = useParams()
    const refForm = useRef()
    const refFormPartner = useRef()
    const refFormContact = useRef()
    const refFormAddress = useRef()

    const actions = [
        // { title:'Cancelar', outline:true, action:() => history.goBack() },
        { title:'Salvar', action:() => save(), loadable:true }
    ]
    
    const formItems = [
        { placeholder:"Razão Social", ref:"corporateReason", half:true },
        { placeholder:"Nome fantasia da empresa", ref:"fantasyName", half:true },

        { placeholder:"CNPJ", ref:"cnpj", quarter:true, mask:"99.999.999/9999-99" },
        { placeholder:"Classificação tributária", ref:"taxClassification", quarter:true, options:tributeOptions },
        { placeholder:"Tipo de folha", ref:"typeSheet", quarter:true, options:typeSheetOptions, disabled:true },
        { placeholder:"Plano contratado", ref:"plan", quarter:true, options:plansOptions, disabled:true },

        { separator:true },

        { placeholder:"Empresa matriz", ref:"parentCompany" },
        { placeholder:"Empresa filial", ref:"branchCompany" },
        { placeholder:"CNPJ da empresa matriz", ref:"parentCompanyCNPJ", mask:"99.999.999/9999-99" },

        { placeholder:"Nome do responsável", ref:"responsibleName", quarter:true },
        { placeholder:"DDD + Telefone", ref:"phone", quarter:true, mask:"(99) 99999-9999" },
        { placeholder:"Whatsapp", ref:"whatsapp", quarter:true, mask:"(99) 99999-9999"  },
        { placeholder:"E-mail", ref:"email", quarter:true }
    ]

    const partnerItems = [
        { placeholder:"Nome", ref:"name", half:true },
        { placeholder:"DDD + Telefone", ref:"phone", half:true, mask:"(99) 99999-9999"  },
        { placeholder:"E-mail", ref:"email", half:true },
        { placeholder:"CPF", ref:"cpf", half:true, mask:"999.999.999-99"  },
    ]
    
    const contactItems = [
        { placeholder:"Nome", ref:"name" },
        { placeholder:"DDD + Telefone", ref:"phone", mask:"(99) 99999-9999"  },
        { placeholder:"E-mail", ref:"email" },
    ]
    
    const addressItems = [
        { placeholder:"Cep (Opcional)", ref:"zipcode", mask:'99999-999', onBlur:value => fillAddress(value) },
        { placeholder:"Endereço (Opcional)", ref:"street" },
        { placeholder:"Número (Opcional)", ref:"number" },
        // { placeholder:"Complemento (Opcional)", ref:"complement", quarter:true },
        // { placeholder:"Estado (Opcional)", ref:"state", quarter:true, options:[] },
        // { placeholder:"Cidade (Opcional)", ref:"city", quarter:true, options:[] },
        { placeholder:"Estado (Opcional)", ref:"state" },
        { placeholder:"Cidade (Opcional)", ref:"city" },
        { placeholder:"Bairro (Opcional)", ref:"neighborhood" },
    ]
    
    const [loading, setLoading] = useState(false)
    const [register, setRegister] = useState(null)
    const [saving, setSaving] = useState(false)
    
    const init = async () => {
        setLoading(true)
        const result = await ReadOne(currentCompany?.id)
        if(result?.data?.id){
            const normalResult = normalizeStrapiRegister(result)
            setRegister({
                ...normalResult
            })
        }
        setLoading(false)
    }

    const save = async () => {

        const form = refForm.current.getForm()
        const payload = {
            ...form,
            partners:[
                { ...refFormPartner.current.getForm() },
                { ...refFormContact.current.getForm() },
            ],
            address: {
                ...refFormAddress.current.getForm(),
            }
        }

        delete payload.id

        if(!valid(payload, formItems)){ toast.error("Preencha todos os campos"); return ;}

        setSaving(true)
        const result = currentCompany?.id ? await Update({data:payload}, currentCompany?.id) : await Create({data:payload})

        if(result && !exposeStrapiError(result)){            
            toast.success("Salvo")
            history.goBack()
        } else {
            toast.error("Erro ao salvar, tente novamente mais tarde")
        }

        setSaving(false)
    }

    const valid = (payload, array) => {  
        for(let item of array){
            if(item?.ref && !payload?.[item?.ref]){
                return false;
            }
        }
        return true
    } 

    const fillAddress = async zipCode => {
        const result = await ReadAddressesByZipCode(zipCode?.replace(/\.|\-/g,''))
        if(result){
            let dinamicAddress = {}
            if(result?.bairro){ dinamicAddress = { ...dinamicAddress, neighborhood: result?.bairro } ;}
            if(result?.uf){ dinamicAddress = { ...dinamicAddress, state: result?.uf } ;}
            if(result?.localidade){ dinamicAddress = { ...dinamicAddress, city: result?.localidade } ;}
            if(result?.logradouro){ dinamicAddress = { ...dinamicAddress, street: result?.logradouro } ;}
            if(result?.cep){ dinamicAddress = { ...dinamicAddress, zipcode: result?.cep } ;}
            setRegister({
                ...register,
                ...refFormContact.current.getForm(),
                ...refFormPartner.current.getForm(),
                ...refForm.current.getForm(),
                address:{
                    ...register?.address,
                    ...refFormAddress.current.getForm(),
                    ...dinamicAddress
                }
            })
        }
    }

    useEffect(() => {
        if(currentCompany?.id){ init() ;}
    }, [currentCompany])

    return ( 
        <>
            <DashboardCardWrapper backable title={"Cadastro da empresa"} actions={actions} loading={saving}>
                {/* <DashboardCardLimit inverted/> */}
                <DashboardCardWrapper closeable title={"Dados da empresa"} white>
                    <DashboardFormsCore formItems={formItems} register={register} ref={refForm} />
                </DashboardCardWrapper>

                <DashboardCardWrapper closeable title={"Sócio 1"} white>
                    <DashboardFormsCore formItems={partnerItems} register={register?.partners?.[0]} ref={refFormPartner} />
                </DashboardCardWrapper>

                <DashboardCardWrapper closeable title={"Contato 1"} white>
                    <DashboardFormsCore formItems={contactItems} register={register?.partners?.[1]} ref={refFormContact} />
                </DashboardCardWrapper>

                <DashboardCardWrapper closeable title={"Endereço"} white>
                    <DashboardFormsCore formItems={addressItems} register={register?.address} ref={refFormAddress} />
                </DashboardCardWrapper>
                
                <FormActions> 
                    <DashboardActions actions={actions} loading={saving} />
                </FormActions>

            </DashboardCardWrapper>
        </>
    );
}