import { GET, POST, PUT, DELETE } from './api'

export const Create = async (params) => {
    return await POST(`/holerites`, params, true);
}

export const CreateLogImport = async (params) => {
    return await POST(`/import-holerites`, params, true);
}

export const ReadAll = async (id) => {
    return await GET(`/holerites?filters[company]=${id}&_limit=-1&pagination[pageSize]=1000`, true);
}

export const ReadAllForEmployee = async (id) => {
    return await GET(`/holerites?filters[employee]=${id}&_limit=-1&pagination[pageSize]=1000`, true);
}

export const ReadAllImports = async (id) => {
    return await GET(`/import-holerites?&_limit=-1&pagination[pageSize]=1000`, true);
}

export const ReadHolerite = async (id, cpf, month, year, employee) => {
    return await GET(`/holerites?filters[company]=${id}&filters[cpf]=${cpf}&filters[month]=${month}&filters[year]=${year}&filters[employee]=${employee}`, true);
}

export const Read = async (id) => {
    return await GET(`/holerites?filters[employee]=${id}&populate[0]=employee`, true);
}

export const ReadFullAll = async () => {
    return await GET(`/holerites?populate[0]=employee&_limit=-1&pagination[pageSize]=1000`, true);
}

export const ReadOne = async (id) => {
    return await GET(`/holerites/${id}?populate=*`, true);
}

export const Update = async (params, id) => {
    return await PUT(`/holerites/${id}`, params, true);
}

export const Delete = async (id) => {
    return await DELETE(`/holerites/${id}`, true);
}
export const DeleteLogImports = async (id) => {
    return await DELETE(`/import-holerites/${id}`, true);
}

export const ReadAllByUploadId = async (uploadId) => {
    return await GET(`/holerites?filters[uploadId]=${uploadId}&_limit=-1&pagination[pageSize]=1000`, true);
}

export const GeneratePdf = async (ids) => {
    return await POST(`/holerites/generate-pdf`, { ids }, true, true);
}
